import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faAtlas, faWallet, faArchive} from '@fortawesome/free-solid-svg-icons';

import './Footer.sass';

const Footer = (props) => (
	<footer className="main-footer">
		<div className={props.fullScreen ? 'container-fluid' : 'container'}>
			<div className="row">
				<div className="col-md-6 text-center text-md-left">
					Ouroboros Followers
				</div>
				<div className="col-md-6 mt-3 mt-md-0 text-center text-md-right">

					<ul className="socials-list">
						<li><a href="https://ouroboros-crypto.com/" rel="noopener noreferrer" target="_blank" className="social-link"><FontAwesomeIcon size="lg" color="#8A8A8A" icon={faAtlas} /></a></li>
						<li><a href="https://wallet.ouroboros-crypto.com/" rel="noopener noreferrer" target="_blank" className="social-link"><FontAwesomeIcon size="lg" color="#8A8A8A" icon={faWallet} /></a></li>
						<li><a href="https://docs.ouroboros-crypto.com/" rel="noopener noreferrer" target="_blank" className="social-link"><FontAwesomeIcon size="lg" color="#8A8A8A" icon={faArchive} /></a></li>
					</ul>

				</div>
			</div>
		</div>
	</footer>
);

export default connect(
	state => ({ fullScreen: state.Style.fullScreen }),
	null
)(Footer);
