// Загружаем структуру
import { updateTops } from "../store/actions/tops";

export default function loadTopsAction() {
    return dispatch => {
        fetch('https://microservice.ouroboros-crypto.com/tops')
            .then(res => {
                return res.json();
            })
            .then(res => {
                dispatch(updateTops(res));

                return res;
            })
            .catch(error => {
                console.log(error);
            });
    }
}