// Загружаем структуру
import {loadingStructure, updateStructure} from "../store/actions/structure";

export default function loadStructureAction(address) {
    return dispatch => {
        dispatch(loadingStructure());

        fetch('https://microservice.ouroboros-crypto.com/structure/' + address)
            .then(res => {
                return res.json();
            })
            .then(res => {
                dispatch(updateStructure(res));

                return res;
            })
            .catch(error => {
                console.log(error);
            });
    }
}