import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardBody, Table, Spinner, Input, InputGroup, Button } from 'reactstrap';

import loadStructureAction from "../../api/loadStructure";

import Graph from '../../components/Graph';

import './GraphPage.sass';
import { Link } from "react-router-dom";

class GraphPage extends Component {
    constructor(props) {
        super(props);

        this.onNodeSelect = this.onNodeSelect.bind(this);
        this.backToRoot = this.backToRoot.bind(this);
        this.findWallet = this.findWallet.bind(this);

        this.state = {
            owner: '',
            selectedNode: '',
            activatedBy: '',
            wallet: '',
            balance: '0.00',
            structure: '0.00',
            level: 0
        }
    }

    componentDidMount() {
        const {address} = this.props.match.params;

        this.props.dispatch(loadStructureAction(address));

        this.setState({
            owner: address,
            selectedNode: address
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.address !== this.props.match.params.address) {
            this.props.dispatch(loadStructureAction(this.props.match.params.address));

            this.setState({
                owner: this.props.match.params.address,
                selectedNode: this.props.match.params.address,
            })
        }
    }


    onNodeSelect(data) {
        this.setState({
            selectedNode: data.id,
            activatedBy: data.activatedBy,
            level: data.level,
            balance: data.balance,
            structure: data.structure
        });
    }

    backToRoot(e) {
        e.preventDefault();

        this.graph.focusOnNode('node[level=0]');
    }

    findWallet(e) {
        e.preventDefault();

        //this.graph.focusOnNode('node[id=' + this.state.wallet + ']')
        this.graph.focusOnNode('node[id="' + this.state.wallet + '"]');
    }

    render() {
        let graphPageContent = null;

        if (this.props.structure.loading) {
            graphPageContent = (
                <div className="text-center mb-3" id="cytoscape-graph" style={{'lineHeight': '350px'}}>
                    <Spinner size="lg" color="primary"/>
                </div>
            );
        } else {
            graphPageContent = (
                <Graph onRef={ref => (this.graph = ref)} onNodeSelect={this.onNodeSelect}/>
            );
        }

        let data = {
            'selectedNode': this.props.structure.owner,
            'balance': this.props.structure.balance,
            'activatedBy': this.props.structure.activatedBy,
            'structure': this.props.structure.structure,
            'level': 0
        };

        if (this.state.selectedNode !== this.props.structure.owner) {
            data = this.state;
        }

        return (
            <section className="graph-page-sect">
                <div className={this.props.fullScreen ? 'container-fluid' : 'container'}>
                    <div className="row">
                        <div className="col-12">

                            {graphPageContent}

                            <Table className="wallet-info-table mt-4" dark responsive>
                                <thead>
                                <tr>
                                    <th>Выбранный кошелёк:</th>
                                    <th>Кошелёк активировал:</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td><Link to={"/structure/" + data.selectedNode}>{data.selectedNode}</Link></td>
                                    <td><Link to={"/structure/" + data.activatedBy}>{data.activatedBy}</Link></td>
                                </tr>
                                </tbody>
                            </Table>

                            <Table className="wallet-info-table mt-4" dark responsive>
                                <thead>
                                <tr>
                                    <th>Уровень:</th>
                                    <th>Баланс:</th>
                                    <th>Структура:</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{data.level}</td>
                                    <td>{data.balance.toLocaleString('fr').replace(',', '.')}</td>
                                    <td>{data.structure.toLocaleString('fr').replace(',', '.')}</td>
                                </tr>
                                </tbody>
                            </Table>

                            <Card className="legends-card mt-4">
                                <CardBody>
                                    <h5 className="mb-3">Сумма OURO в кошельке</h5>

                                    <div className="legends-list">
                                        <div class="block-legend"><div class="node legend-white"></div>&nbsp;<span>&lt; 1</span></div>
                                        <div class="block-legend"><div class="node legend-red"></div>&nbsp;<span>1 - 99</span></div>
                                        <div class="block-legend"><div class="node legend-orange"></div>&nbsp;<span>100 - 999</span></div>
                                        <div class="block-legend"><div class="node legend-yellow"></div>&nbsp;<span>1.000 - 9999</span></div>
                                        <div class="block-legend"><div class="node legend-green"></div>&nbsp;<span>10.000 - 49.999</span></div>
                                        <div class="block-legend"><div class="node legend-aqua"></div>&nbsp;<span>50.000 - 99.999</span></div>
                                        <div class="block-legend"><div class="node legend-blue"></div>&nbsp;<span>100.000 - 499.999</span></div>
                                        <div class="block-legend"><div class="node legend-magenta"></div>&nbsp;<span>500.000 - 999.999</span></div>
                                        <div class="block-legend"><div class="node legend-pink"></div>&nbsp;<span>&gt; 1.000.000</span></div>
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="wallet-search-wrap mt-4">
                                <InputGroup className="search-input-group">
                                    <Input
                                        placeholder="Введите адрес кошелька..."
                                        onChange={e => this.setState({wallet: e.target.value})}
                                        value={this.state.wallet}
                                    />
                                </InputGroup>

                                <Button color="primary" size="md" className="search-btn" onClick={this.findWallet}>Найти
                                    кошелек</Button>
                            </div>

                            <Button className="mt-4" color="success" size="lg" block onClick={this.backToRoot}>Вернуться
                                к главному кошельку</Button>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStatetoProps = state => {
    return {
        structure: state.Structure,
        fullScreen: state.Style.fullScreen
    };
};


export default connect(mapStatetoProps)(GraphPage);
