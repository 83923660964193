import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

// Views
import PageHome from './views/PageHome';
import GraphPage from './views/GraphPage';

import './assets/sass/styles.sass';

function App() {
  return (
    <Switch>
      <Route path="/" component={PageHome} exact />
      <Route path="/structure/:address" component={GraphPage} exact />
    </Switch>
  );
}

export default withRouter(App);
