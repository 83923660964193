import {UPDATE_TOPS} from "../actions/tops";

export const INITIAL_STATE = {
    balance: [],
    structure: [],
    emission: 0,
};

export default function (state = INITIAL_STATE, action) {
    if (action.type === UPDATE_TOPS) {
        return {
            ...state,
            balance: action.data.data.balance,
            structure: action.data.data.structure,
            emission: action.data.data.emission,
        };
    }

    return state;
}