export const UPDATE_STRUCTURE = 'UPDATE_STRUCTURE';
export const LOADING_STRUCTURE = 'LOADING_STRUCTURE';


export function updateStructure(data) {
    return {
        type: UPDATE_STRUCTURE,
        data: data
    }
}

export function loadingStructure() {
    return {
        type: LOADING_STRUCTURE
    }
}
