import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setFullscreen } from '../../store/actions/style';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompressArrowsAlt, faCompress } from '@fortawesome/free-solid-svg-icons'

import {
    InputGroup,
    Input,
    Button
} from 'reactstrap';

import './Header.sass';

class Header extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);

        this.state = {
            address: ''
        }
    }

    onSubmit(e) {
        e.preventDefault();

        this.props.history.push('/structure/' + this.state.address);

        this.setState({
            address: ''
        });
    }

    render() {
        return (
            <header className="main-header">
                <div className={this.props.fullScreen ? 'container-fluid' : 'container'}>
                    <div className="row">
                        <div className="col-sm-2">

                            <div onClick={() => this.props.setFullscreen(!this.props.fullScreen)} className="full-screen-toggle">
                                <FontAwesomeIcon icon={this.props.fullScreen ? faCompressArrowsAlt : faCompress} />
                            </div>

                            <div className="site-logo-wrapper">
                                <Link to="/" className="site-logo"></Link>
                            </div>

                        </div>
                        <div className="col-sm-8 offset-sm-1 col-lg-6 offset-lg-2 mt-4 mt-sm-0">
                            <form onSubmit={this.onSubmit}>

                            <div className="site-search-wrapper">
                                <InputGroup className="site-search">
                                    <Input value={this.state.address}
                                           onChange={e => this.setState({address: e.target.value})}
                                           placeholder="Введите адрес кошелька..."/>
                                </InputGroup>

                                <Button className="site-search-btn"
                                        color="warning" size="md">Найти</Button>
                            </div>
                            </form>

                        </div>
                    </div>
                </div>
            </header>
        )
    }

};

export default withRouter(connect(state => ({
    fullScreen: state.Style.fullScreen
}), {
    setFullscreen
})(Header));
