import React from 'react';

// Components
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Layout = (props) => (
	<div className="main-wrapper">
		<Header />

		<main className="main">
			{props.children}
		</main>
		
		<Footer />
	</div>
);

export default Layout;
