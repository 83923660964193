import React, {Component} from 'react';
import {connect} from "react-redux";
import CytoscapeComponent from "react-cytoscapejs";
import dagre from 'cytoscape-dagre';
import Cytoscape from 'cytoscape';

Cytoscape.use(dagre);

const layout = {
    name: 'dagre',
    fit: false,
    nodeSep: 120,
};

const style = [
    {
        selector: 'node',
        style: {
            'width': 5,
            'height': 5,
            'content': 'data(name)',
            'font-size': 5,
            'overlay-padding': 4,
            'text-valign': 'top',
            'background-color': '#28A745'
        }
    },
    {
        selector: 'node[balance < 1]',
        css: {
            'background-color': '#D3D3D3'
        }
    },
    {
        selector: 'node[balance > 1]',
        css: {
            'background-color': '#CA3142'
        }
    },
    {
        selector: 'node[balance > 99]',
        css: {
            'background-color': '#D48732'
        }
    },
    {
        selector: 'node[balance > 999]',
        css: {
            'background-color': '#FFFD54'
        }
    },
    {
        selector: 'node[balance > 9999]',
        css: {
            'background-color': '#75F94C'
        }
    },
    {
        selector: 'node[balance > 49999]',
        css: {
            'background-color': '#74FBFD'
        }
    },
    {
        selector: 'node[balance > 99999]',
        css: {
            'background-color': '#0024F5'
        }
    },
    {
        selector: 'node[balance > 499999]',
        css: {
            'background-color': '#EA3FF7'
        }
    },
    {
        selector: 'node[balance > 999999]',
        css: {
            'background-color': '#F6C3CB'
        }
    },
    {
        selector: ':selected',
        style: {
            'background-color': 'red',
            'line-color': 'yellow',
            'target-arrow-color': '#fff',
            'source-arrow-color': '#fff',
        }
    },
    {
        selector: 'edge',
        style: {
            'width': 1,
            'font-size': 10,
            'target-arrow-shape': 'triangle',
            'overlay-padding': 4,
            'opacity': 0.5
        }
    },
    {
        selector: 'label',
        style: {
            color: '#fff'
        }
    }
];

class Graph extends Component {
    // Never do this at home kids
    componentDidMount() {
        this.props.onRef(this);
    }

    // NEVER
    componentWillUnmount() {
        this.cy.destroy();
        this.props.onRef(undefined);
    }

    //riii
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    focusOnNode(node) {
        const elementsNode = this.cy.elements(node)[0];

        this.cy.elements(':selected').unselect();

        elementsNode.select();

        this.props.onNodeSelect(elementsNode.data());

        this.cy.animate({
            fit: {
                eles: elementsNode,
                padding: 100
            },
            duration: 100,
            easing: 'ease',
            queue: true
        });
    }

    render() {
        const levels = {};

        const nodes = [];
        const edges = [];

        const rootNode = {
            data: {
                id: this.props.structure.owner,
                label: this.props.structure.owner,
                activatedBy: this.props.structure.activatedBy,
                level: 0,
                balance: this.props.structure.balance,
                structure: this.props.structure.structure,
            }
        };

        if (this.props.structure.owner) {
            nodes.push(rootNode);

            for (let i in this.props.structure.followers) {
                const follower = this.props.structure.followers[i];

                const level = follower[2];
                let y = levels[level];

                if (y === undefined) {
                    levels[level] = 0;
                    y = 1;
                }

                levels[level] += 1;

                nodes.push({
                    data: {id: follower[0], activatedBy: follower[1], label: follower[0], name: follower[0], level: level, balance: follower[3], structure: follower[4], height: 10 * level},
                });

                edges.push({data: {source: follower[1], target: follower[0], label: 'Edge from Node1 to Node2'}})
            }
        }

        const elements = nodes.concat(edges);

        return (
            <div id="cytoscape-graph">
                <CytoscapeComponent cy={(cy) => {
                    this.cy = cy;

                    const view = this;

                    const rootNode = this.cy.elements('node[level=0]')[0];

                    rootNode.select();

                    this.cy.animate({
                        fit: {
                            eles: rootNode,
                            padding: 100
                        },
                        duration: 100,
                        easing: 'ease',
                        queue: true
                    });

                    this.cy.on('tap', 'node', function (evt) {
                        view.props.onNodeSelect(evt.target.data());
                    });
                }} elements={elements} zoom={0.5} layout={layout}
                                    pixelRatio={'auto'}
                                    minZoom={0.1}
                                    maxZoom={3}
                                    style={{width: '100%', height: '400px'}}
                                    stylesheet={style}/>
            </div>
        );
    }
}

const mapStatetoProps = state => {
    return {
        structure: state.Structure,
    };
};

export default connect(mapStatetoProps)(Graph);