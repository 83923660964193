import { SET_FULLSCREEN } from "../actions/style";

export const INITIAL_STATE = {
    fullScreen: false
};

export default function (state = INITIAL_STATE, action) {
    if (action.type === SET_FULLSCREEN) {
        return {
            ...state,
            fullScreen: action.fullScreen
        };
    }

    return state;
}