import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	Card,
	CardHeader,
	CardBody,
	ListGroup,
	ListGroupItem,
	Badge,
	Jumbotron
} from 'reactstrap';
import loadTopsAction from "../../api/loadTops";

// Components
import Aux from '../../hoc/Auxilary';

import './PageHome.sass';

class PageHome extends Component {

	componentDidMount() {
		this.props.dispatch(loadTopsAction());
	}

	render() {
		return (
			<Aux>
				<section className="top-wallets-sect">
					<div className={this.props.fullScreen ? 'container-fluid' : 'container'}>
						<div className="row">
							<div className="col-12">

								<Card className="top-wallets-card" style={{backgroundColor: '#3A3F44'}}>
									<CardHeader><h5>Топ-кошельков, баланс</h5></CardHeader>

									<CardBody>
										<ListGroup className="wallets-list">
											{this.props.tops.balance.map((value, index) => {
												return <ListGroupItem style={{backgroundColor: '#F0F0F0'}} key={value[0]} tag="a" href={"/structure/" + value[0]} action>{value[0]} <Badge color="success" pill className="ml-2">{parseInt(value[1], 10).toLocaleString('fr').replace(',', '.')}</Badge></ListGroupItem>;
											})}
										</ListGroup>
									</CardBody>
								</Card>

							</div>
							<div className="col-12 mt-4">

								<Card className="top-wallets-card" style={{backgroundColor: '#3A3F44'}}>
									<CardHeader><h5>Топ-кошельков, объем структуры</h5></CardHeader>

									<CardBody>
										<ListGroup className="wallets-list">
											{this.props.tops.structure.map((value, index) => {
												return <ListGroupItem style={{backgroundColor: '#F0F0F0'}} key={value[0]} tag="a" href={"/structure/" + value[0]} action>{value[0]} <Badge color="success" pill className="ml-2">{parseInt(value[1]).toLocaleString('fr').replace(',', '.')}</Badge></ListGroupItem>;
											})}
										</ListGroup>
									</CardBody>
								</Card>

							</div>
						</div>
					</div>
				</section>

				<section className="current-capital-sect pt-2">
					<div className={this.props.fullScreen ? 'container-fluid' : 'container'}>
						<div className="row">
							<div className="col-12">

								<Jumbotron style={{backgroundColor: '#3A3F44'}}>
									<h4 className="sect-title text-center">Общая Статистики</h4>

									<ListGroup className="current-capital-list mt-md-5">
										<ListGroupItem style={{backgroundColor: '#F0F0F0'}} className="justify-content-between">Капитализация <Badge color="info" pill>{this.props.tops.emission.toLocaleString('fr').replace(',', '.')}</Badge></ListGroupItem>
										<ListGroupItem style={{backgroundColor: '#F0F0F0'}} className="justify-content-between">Намайнено <Badge color="info" pill>{(this.props.tops.emission - 1e7).toLocaleString('fr').replace(',', '.')}</Badge></ListGroupItem>
									</ListGroup>
								</Jumbotron>

							</div>
						</div>
					</div>
				</section>
			</Aux>
		)
	}

};

const mapStatetoProps = state => {
	return {
		tops: state.Tops,
		fullScreen: state.Style.fullScreen
	};
};

export default connect(mapStatetoProps)(PageHome);
