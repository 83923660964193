import {LOADING_STRUCTURE, UPDATE_STRUCTURE} from "../actions/structure";

export const INITIAL_STATE = {
    loading: true,
    owner: "",
    activatedBy: "",
    balance: "",
    structure: "",
    followers: []
};

export default function (state = INITIAL_STATE, action) {
    if (action.type === LOADING_STRUCTURE) {
        return {
            ...state,
            loading: true
        };
    }

    if (action.type === UPDATE_STRUCTURE) {
        return {
            ...state,
            owner: action.data.data.owner,
            activatedBy: action.data.data.activated_by,
            followers: action.data.data.followers,
            balance: action.data.data.balance,
            structure: action.data.data.structure,
            loading: false
        };
    }

    return state;
}